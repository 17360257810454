import React, { useEffect, useState } from 'react';
import { Button, Select, MenuItem, Tab, Tabs } from '@material-ui/core';
import styles from '../user-list/user-list.module.css';
import { Route, Redirect } from 'react-router-dom';
import { UserListContainer } from '../user-list/user-list.container';
import { Routes } from '../../constants/routes';

export function UserManagementContainer({ match, history, location }) {

    const [activeTab, setActiveTab] = useState('agents');

    const handleTabChange = (event, value) => {
        setActiveTab(value);
        history.push(`${match.url}/${value}`);
    };

    useEffect(() => {
        const splitedUrl = location.pathname.split('/');
        setActiveTab(splitedUrl[splitedUrl.length - 1]);
    }, [location.pathname]);

    const goToCreateUser = () => {
        history.push(Routes.CREATE_USER.path);
    };

    return (
        <div className={styles.UserListContainer}>
            <div className={styles.NavigationContainer}>
                <Tabs
                    value={activeTab}
                    indicatorColor='primary'
                    textColor='primary'
                    onChange={handleTabChange}>
                    <Tab label='Agents' value={'agents'}/>
                    <Tab label='Staff' value={'staff'}/>
                </Tabs>
                <Button onClick={goToCreateUser}>Create new user</Button>
            </div>
            <Route exact path={`${match.path}/staff`} component={UserListContainer}/>
            <Route exact path={`${match.path}/agents`} component={UserListContainer}/>
            <Route exact path={`${match.path}`} render={() => (<Redirect to={`${match.path}/agents`}/>)}/>
        </div>);
}
