import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import styles from '../user-list/user-list.module.css';
import { getTableConfig } from '../user-management/user-list-table.config';

export function LocationsList({ match, history, location }) {


    const [tableConf, setTableConf] = useState(null);


    const getUserTypeFromUrl = (url) => {
        const lastUrlSegment = url.split('/');
        return lastUrlSegment[lastUrlSegment.length - 1];
    };

    useEffect(() => {
        const userType = getUserTypeFromUrl(location.pathname);
        setTableConf(getTableConfig(userType));
    }, [location.pathname]);

    const materialTableRef = React.createRef();

    return (
        <div className={styles.UserListContainer}>
            {tableConf ? <MaterialTable
                tableRef={materialTableRef}
                columns={tableConf.columns}
                title='Demo Title'
                data={tableConf.data}

                options={{
                    filtering: true,
                    headerStyle: {
                        backgroundColor: '#F3F0F0',
                        color: '#666464',
                    },
                    rowStyle: {
                        cursor: 'pointer',
                    },
                    pageSize: 50,
                    pageSizeOptions: [50, 100, 200],
                    emptyRowsWhenPaging: false
                }}
                actions={tableConf.actions.map(action => action.bind(materialTableRef))}
                onRowClick={(event, data) => tableConf.onRowClickHandler(event, data, history)}
            /> : null}
        </div>
    );
}
