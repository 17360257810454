import axios from "axios";

export const apiUrl = process.env.REACT_APP_API_URL;

export const httpService =  axios.create({
    baseURL: `${apiUrl}/`,
    responseType: "json"
});

httpService.interceptors.request.use(request => {
    let token = localStorage.getItem('token');
    if (token) {
        request.headers['X-Authorization'] = token;
    }
    request.headers['X-Content-Type'] = 'application/json';
    request.headers['Content-Type'] = 'application/json';

    return request;
});