import React, { useEffect, useState } from 'react';
import { Button, Select, MenuItem, Tab, Tabs } from '@material-ui/core';
import styles from '../user-list/user-list.module.css';
import { Route, Redirect } from 'react-router-dom';
import { LocationsList } from './locations-list.component';

export function LocationsManagement({ match, history, location }) {

    const [activeTab, setActiveTab] = useState('exported');

    const handleTabChange = (event, value) => {
        setActiveTab(value);
        history.push(`${match.url}/${value}`);
    };

    useEffect(() => {
        const splitedUrl = location.pathname.split('/');
        setActiveTab(splitedUrl[splitedUrl.length - 1]);
    }, [location.pathname]);


    return (
        <div className={styles.UserListContainer}>

            <Tabs
                value={activeTab}
                indicatorColor='primary'
                textColor='primary'
                onChange={handleTabChange}>
                <Tab label='Exported' value={'exported'}/>
                <Tab label='Not exported' value={'not-exported'}/>
                <Tab label='All' value={'all'}/>
            </Tabs>
            <Route exact path={`${match.path}/exported`} component={LocationsList}/>
            <Route exact path={`${match.path}/not-exported`} component={LocationsList}/>
            <Route exact path={`${match.path}/all`} component={LocationsList}/>
            <Route exact path={`${match.path}`} render={() => (<Redirect to={`${match.path}/exported`}/>)}/>
        </div>);
}
